<template>
  <section class="privacy-policy">
    <h1 class="privacy-policy__title">Polityka Prywatności</h1>

    <div class="privacy-policy__content">
      <h2 class="privacy-policy__subtitle"><strong>1. Informacje ogólne</strong></h2>
      <ol class="privacy-policy__list">
        <li>
          Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url:
          <b>maksym.net.pl</b>
        </li>
        <li>
          Operatorem serwisu oraz Administratorem danych osobowych jest: MAKSYM
          Głowackiego 1A, 66-440 Skwierzyna
        </li>

        <li>Adres kontaktowy poczty elektronicznej operatora: maksym.stihl@o2.pl</li>

        <li>
          Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych
          podanych dobrowolnie w Serwisie.
        </li>
        <li>Serwis wykorzystuje dane osobowe w następujących celach:</li>
        <ul class="privacy-policy__list privacy-policy__list--unnumber-list">
          <li>Prezentacja oferty lub informacji</li>
          <li>Obsługa zapytań przez formularz</li>
        </ul>
        <li>
          Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich
          zachowaniu w następujący sposób:
          <ol class="privacy-policy__list privacy-policy__list--sub-list">
            <li>
              Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają
              wprowadzone do systemów Operatora.
            </li>
            <li>
              Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw.
              „ciasteczka”).
            </li>
          </ol>
        </li>
      </ol>
      <h2 class="privacy-policy__subtitle">
        <strong>2. Wybrane metody ochrony danych stosowane przez Operatora</strong>
      </h2>
      <ol class="privacy-policy__list">
        <li>
          Miejsca wprowadzania danych osobowych są chronione w warstwie transmisji
          (certyfikat SSL). Dzięki temu dane osobowe, wprowadzone na stronie, zostają
          zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym
          serwerze.
        </li>
        <li>Operator okresowo zmienia swoje hasła administracyjne.</li>
        <li>
          Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego
          oprogramowania, wykorzystywanego przez Operatora do przetwarzania danych
          osobowych, co w szczególności oznacza regularne aktualizacje komponentów
          programistycznych.
        </li>
      </ol>
      <h2 class="privacy-policy__subtitle"><strong>3. Hosting</strong></h2>
      <ol class="privacy-policy__list">
        <li>
          Serwis jest hostowany (technicznie utrzymywany) na serwera operatora: home.pl
        </li>
        <li>
          Pod adresem
          <text-links
            classes="privacy-policy__link"
            :IsExternalLink="true"
            href="https://home.pl"
          >
            https://home.pl
          </text-links>
          możesz dowiedzieć się więcej o hostingu i sprawdzić politykę prywatności firmy
          hostingowej.
        </li>
      </ol>
      <h2 class="privacy-policy__subtitle">
        <strong
          >4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych</strong
        >
      </h2>
      <ol class="privacy-policy__list">
        <li>
          W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe
          innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy
          lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich
          grup odbiorców:
          <ul class="privacy-policy__list privacy-policy__list--unnumber-list">
            <li>
              upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w celu
              realizacji celu działania strony,
            </li>
            <li>firmy obsługująca mailingi,</li>
            <li>
              firmy, z którymi Administrator współpracuje w zakresie marketingu własnego,
            </li>
            <li>kurierzy,</li>
          </ul>
        </li>
        <li>
          Twoje dane osobowe są przetwarzane przez Administratora nie dłużej, niż jest to
          konieczne do wykonania związanych z nimi czynności określonych osobnymi
          przepisami (np. o prowadzeniu rachunkowości). W odniesieniu do danych
          marketingowych dane nie będą przetwarzane dłużej niż przez 3 lata.
        </li>
        <li>
          Przysługuje Ci prawo żądania od Administratora:
          <ul class="privacy-policy__list privacy-policy__list--unnumber-list">
            <li>dostępu do danych osobowych Ciebie dotyczących,</li>
            <li>ich sprostowania,</li>
            <li>usunięcia,</li>
            <li>ograniczenia przetwarzania,</li>
            <li>oraz przenoszenia danych.</li>
          </ul>
        </li>
        <li>
          Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w
          pkt 3.3 c) wobec przetwarzania danych osobowych w celu wykonania prawnie
          uzasadnionych interesów realizowanych przez Administratora, w tym profilowania,
          przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia
          ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie
          interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub obrony
          roszczeń.
        </li>
        <li>
          Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych
          Osobowych, ul. Stawki 2, 00-193 Warszawa.
        </li>
        <li>
          Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu.
        </li>
        <li>
          W stosunku do Ciebie mogą być podejmowane czynności polegające na
          zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu świadczenia
          usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora
          marketingu bezpośredniego.
        </li>
        <li>
          Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu przepisów o
          ochronie danych osobowych. Oznacza to, że nie przesyłamy ich poza teren Unii
          Europejskiej.
        </li>
      </ol>
      <h2 class="privacy-policy__subtitle">
        <strong>5. Informacje w formularzach</strong>
      </h2>
      <ol class="privacy-policy__list">
        <li>
          Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane
          osobowe, o ile zostaną one podane.
        </li>
        <li>
          Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres
          IP).
        </li>
        <li>
          Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie
          danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W
          takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony
          zawierającej formularz.
        </li>
        <li>
          Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie
          danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W
          takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony
          zawierającej formularz.
        </li>
        <li>
          Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji
          konkretnego formularza, np. w celu dokonania procesu obsługi zgłoszenia
          serwisowego lub kontaktu handlowego, rejestracji usług itp. Każdorazowo kontekst
          i opis formularza w czytelny sposób informuje, do czego on służy.
        </li>
      </ol>

      <h2 class="privacy-policy__subtitle">
        <strong>6. Istotne techniki marketingowe</strong>
      </h2>
      <ol class="privacy-policy__list">
        <li>
          Operator stosuje techniki remarketingowe, pozwalające na dopasowanie przekazów
          reklamowych do zachowania użytkownika na stronie, co może dawać złudzenie, że
          dane osobowe użytkownika są wykorzystywane do jego śledzenia, jednak w praktyce
          nie dochodzi do przekazania żadnych danych osobowych od Operatora do operatorom
          reklam. Technologicznym warunkiem takich działań jest włączona obsługa plików
          cookie.
        </li>
        <li>
          Operator stosuje rozwiązanie automatyzujące działanie Serwisu w odniesieniu do
          użytkowników, np. mogące przesłać maila do użytkownika po odwiedzeniu konkretnej
          podstrony, o ile wyraził on zgodę na otrzymywanie korespondencji handlowej od
          Operatora.
        </li>
        <li>
          Operator może stosować profilowanie w rozumieniu przepisów o ochronie danych
          osobowych
        </li>
      </ol>

      <h2 class="privacy-policy__subtitle">
        <strong>7. Informacja o plikach cookies</strong>
      </h2>
      <ol class="privacy-policy__list">
        <li>Serwis korzysta z plików cookies.</li>
        <li>
          Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności
          pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu
          i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies
          zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas
          przechowywania ich na urządzeniu końcowym oraz unikalny numer.
        </li>
        <li>
          Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki
          cookies oraz uzyskującym do nich dostęp jest operator Serwisu.
        </li>
        <li>
          Pliki cookies wykorzystywane są w następujących celach:
          <ol class="privacy-policy__list privacy-policy__list--sub-list">
            <li>
              realizacji celów określonych powyżej w części "Istotne techniki
              marketingowe";
            </li>
          </ol>
        </li>
        <li>
          W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne”
          (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są
          plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika
          do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia
          oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane
          są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików
          cookies lub do czasu ich usunięcia przez Użytkownika.
        </li>
        <li>
          Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa)
          zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu
          końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym
          zakresie.&nbsp;Przeglądarka internetowa umożliwia usunięcie plików cookies.
          Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje
          na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.
        </li>
        <li>
          Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności
          dostępne na podstronach Serwisu.
        </li>
        <li>
          Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu
          wykorzystywane mogą być również przez współpracujące z operatorem Serwisu
          podmioty, w szczególności dotyczy to firmy Google (Google Inc. z siedzibą w
          USA).
        </li>
      </ol>
      <h2 class="privacy-policy__subtitle">
        <strong
          >8. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?</strong
        >
      </h2>
      <ol class="privacy-policy__list">
        <li>
          Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia
          przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla
          procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika
          może utrudnić,&nbsp;a w skrajnych przypadkach może uniemożliwić korzystanie ze
          stron www
        </li>
        <li>
          W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę
          internetową, której używasz i postępuj zgodnie z instrukcjami:
          <ul class="privacy-policy__list privacy-policy__list--unnumber-list">
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history"
              >
                Edge
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer"
              >
                Internet Explorer
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647"
              >
                Chrome
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://support.apple.com/kb/PH5042"
              >
                Safari
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek"
              >
                Firefox
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://help.opera.com/Windows/12.10/pl/cookies.html"
              >
                Opera
              </text-links>
            </li>
          </ul>
          <p>Urządzenia mobilne:</p>
          <ul class="privacy-policy__list privacy-policy__list--unnumber-list">
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647"
              >
                Android
              </text-links>
            </li>
            <li>
              <text-links
                classes="privacy-policy__link"
                :IsExternalLink="true"
                href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL"
              >
                Safari (iOS)
              </text-links>
            </li>
            <li>
              <text-links
                href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings"
                classes="privacy-policy__link"
                :IsExternalLink="true"
              >
                Windows Phone
              </text-links>
            </li>
          </ul>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  beforeRouteLeave(_, __, next) {
    if (this.$store.getters.isPhoneMenuOpen) {
      this.$store.commit("openClosePhoneMenu");
    }
    next();
  },
  created() {
    setTimeout(() => {
      this.$store.commit("appearHiddenLoader", false);
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.privacy-policy {
  @apply flex flex-wrap w-full p-3;
  @media (max-width: 421px) {
    @apply p-0;
  }
  &__title {
    @apply w-full flex justify-start items-center text-6xl font-bold text-main;
    margin: 0.67em 1.25%;
    @media (max-width: 428px) {
      @apply text-5xl justify-center text-center;
    }
    @media (max-width: 379px) {
      @apply text-4xl;
    }
  }
  &__content {
    @apply grid p-3 border-t-2 border-solid border-main w-full;
    @media (max-width: 438px) {
      @apply p-0;
    }
    @media (max-width: 414px) {
      @apply w-screen;
    }
  }
  &__subtitle {
    @apply text-4xl text-black p-4;
    @media (max-width: 438px) {
      width: 85vw;
    }
    @media (max-width: 397px) {
      @apply text-3xl;
    }
    @media (max-width: 385px) {
      @apply text-2xl;
    }
  }
  &__list {
    @apply list-none ml-6;
    counter-reset: items;
    @media (max-width: 438px) {
      width: 85vw;
    }
    & > li {
      @apply text-lg;
      counter-increment: items;
      @media (max-width: 414px) {
        @apply max-w-screen text-base;
      }
      &::before {
        content: counter(items);
        @apply font-bold pr-3 w-6 inline-block text-main;
      }
    }
    &--unnumber-list {
      @apply mt-3 mb-3;
      @media (max-width: 438px) {
        @apply w-auto;
      }
      & > li {
        @apply pl-8 relative;
        &::before {
          content: "";
          @apply w-6 h-6 bg-no-repeat absolute left-2 top-1;
          background-size: 17px 13px;
          background-position: left center;
          background-image: url(../assets/img/check.svg);
          @media (max-width: 414px) {
            @apply top-0;
          }
        }
      }
    }
    &--sub-list {
      @apply mt-3 mb-3;
      @media (max-width: 438px) {
        @apply w-auto;
      }
    }
  }

  &__link {
    @apply text-main;
  }
}
</style>
